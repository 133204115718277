var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{ref:"content",staticClass:"project-content wrapper p-xl",class:{ 'is-open': _vm.isOpen }},[_c('div',{ref:"inner",staticClass:"project-content__wysiwyg-outer"},[_c('GWysiwyg',{staticClass:"project-content__wysiwyg",attrs:{"wysiwyg":_vm.content}})],1),_c('div',{staticClass:"project-content__btn-outer"},[(!_vm.isOpen)?_c('g-action',{ref:"btn",staticClass:"project-content__btn",attrs:{"content":{
        label: _vm.$t('expand'),
        icon: 'downArrow',
        tag: 'button',
        modifiers: ['btn'],
      }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.toggle.apply(null, arguments)}}}):_c('g-action',{ref:"btn",staticClass:"project-content__btn",attrs:{"content":{
        label: _vm.$t('collapse'),
        icon: 'downArrow',
        tag: 'button',
        modifiers: ['btn'],
      }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.toggle.apply(null, arguments)}}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }